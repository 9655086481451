import React from 'react';
import { Flex, ListItem, ListIcon, Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { Link as ChakraLink } from "@chakra-ui/react";

function SidebarItem({ icon, to, label }) {
  const location = useLocation(); // Gets the current location
  const isActive = location.pathname === to; // Checks if the current path is the link's target path

  return (
    <ListItem
      _hover={{
        bg: isActive ? "#E9F2FF" : "blackAlpha.50", // Keep active color or apply hover color
        borderRadius: "md"
      }}
      p={1}
      bg={isActive ? "#E9F2FF" : "transparent"} // Active background color
      borderRadius={isActive ? "md" : "none"}
    >
      <Flex align="center">
        <ListIcon as={icon} color={isActive ? "white" : "gray.300"} mr={6} />
        <ChakraLink
          as={Link}
          to={to}
          style={{ textDecoration: "none" }}
          fontWeight="semibold"
        >
          <Text fontWeight="semibold" m={0}>
            {label}
          </Text>
        </ChakraLink>
      </Flex>
    </ListItem>
  );
}

export default SidebarItem;
