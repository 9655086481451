import React from "react";
import { saveAs } from "file-saver";
import { IconButton, useToast } from "@chakra-ui/react";
import { FaDownload } from "react-icons/fa";
import { logout } from "../../auth";
import axiosInstance from "../../helpers/axiosInstance";

const FileDownload = ({ uploading, campaign_id }) => {
  const toast = useToast();

  const handleDownload = async () => {
    console.log("campaign id", campaign_id);
    try {
      const response = await axiosInstance.post(
        "/campaign/download",
        { campaign_id: campaign_id },
        {
          headers: {
            "Content-Type": "application/json", // Overrides the default Content-Type
          },
          responseType: "blob", // Set responseType to "blob" to handle binary data
        }
      );
      console.log("response", response.data);
      // Extract filename from the Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'output.xlsx'; // Default filename

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch && filenameMatch.length === 2) {
          filename = filenameMatch[1];
        }
      }

      // Save the file with the extracted filename
      saveAs(response.data, filename);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
        toast({
          title: "Session expired",
          description: "Your session has expired. Please login again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error(error);
      }
    }
  };

  return (
    <IconButton
      icon={<FaDownload />}
      aria-label="Download"
      bgColor="transparent"
      _hover="transparent"
      textColor="black"
      onClick={handleDownload}
      isDisabled={uploading}
    />
  );
};

export default FileDownload;




// import React from "react";
// import axios from "axios";
// import { saveAs } from "file-saver";
// import { Button, useToast } from "@chakra-ui/react";
// import { logout } from "../../auth";
// import { useColorMode } from "@chakra-ui/react";
// // import '../styles/fileHandlingPage.css';
// import axiosInstance from "../../helpers/axiosInstance";

// const FileDownload = ({ uploading, campaign_id }) => {
//   const { colorMode } = useColorMode();
//   const toast = useToast();
//   const handleDownload = async () => {
//    // console.log("Downloading function", selectedMinutesId);
//     try {
//       const response = await axiosInstance.post(
//         "/campaign/download",
//         { campaign_id: campaign_id },
//         {
//           headers: {
//             "Content-Type":
//               "application/json", // Overrides the default Content-Type
//           },
//           responseType: "blob", // Set responseType to "blob" to handle binary data
//         }
//       );

//       saveAs(response.data, `output.docx`);
//     } catch (error) {
//       if (error.response && error.response.status === 401) {
//         logout();
//         toast({
//           title: "Session expired",
//           description: "Your session has expired. Please login again.",
//           status: "error",
//           duration: 5000,
//           isClosable: true,
//         });
//       } else {
//         console.error(error);
//       }
//     }
//   };

//   return (
//     <Button
//       _hover={{}}
//       bgColor="blue.500"
//       textColor="white"
//       w="49%"
//       onClick={handleDownload}
//       isDisabled={uploading}
//       mt={2}
//       p={3}
//     >
//       Download
//     </Button>
//   );
// };

// export default FileDownload;
