import React, { useState, useEffect } from "react";
import {
  Box, Flex, Heading, useColorMode, Table, Thead, Tbody, Tr, Th, Td, Button, ButtonGroup, Select, useDisclosure,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon ,ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import Sidebar from "../Sidebar";
import EmailDropdown from "../../common/EmailDropdown";
import EmailThreadModal from "./EmailThreadModal";
import axiosInstance from "../../../helpers/axiosInstance";

function Emails() {
  const { colorMode } = useColorMode();
  const [selectedEmail, setSelectedEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeEmailId, setActiveEmailId] = useState(null);

  useEffect(() => {
    if (selectedEmail) {
      axiosInstance
        .get(`/threads/linked_email/${selectedEmail}?page=${currentPage}`)
        .then((response) => {
          const data = response.data.data;
          setEmails(data.threads.map(thread => ({
            id: thread.conversation_id,
            sender: thread.email,
            subject: thread.subject,
          })));
          setTotalPages(Math.ceil(data.total_count / 8));  // Assuming 10 items per page
        })
        .catch((error) => {
          console.error("Error fetching threads:", error);
          setEmails([]);
        });
    }
  }, [selectedEmail, currentPage]);

  const handleSubjectClick = (id) => {
    setActiveEmailId(id);
    onOpen();
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <Flex>
      <Sidebar />
      <Box flex="1" bg={colorMode === "dark" ? "gray.800" : "white"}>
        <Box
          p={5}
          pl={6}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          h="10.7vh"
          borderBottom="1px"
          borderColor="blackAlpha.200"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="lg" fontWeight="semibold" mt={4} m={0}>
            Inbox
          </Heading>
        </Box>
        <Box
          p={1}
          pl={5}
          pr={5}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          h="7vh"
          borderBottom="1px"
          borderColor="blackAlpha.200"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
         {/* Pagination Controls */}
         <Flex align="center" justifyContent="center" mt={0}  h="4vh">
            <ButtonGroup isAttached variant="outline">
              <Button mt="1px" size="xs" onClick={handlePreviousClick} disabled={currentPage <= 1}>
                <ChevronLeftIcon />
              </Button>
              <Select size="xs" value={currentPage} onChange={(e) => handlePageChange(Number(e.target.value))}>
                {Array.from({ length: totalPages }, (_, i) => (
                  <option key={i + 1} value={i + 1}>{i + 1}</option>
                ))}
              </Select>
              <Button mt="1px" size="xs"  onClick={handleNextClick}
              disabled={currentPage >= totalPages}>
                <ChevronRightIcon />
              </Button>
            </ButtonGroup>
          </Flex>
          <Box w="20%">
            <EmailDropdown
              selectedEmail={selectedEmail}
              setSelectedEmail={setSelectedEmail}
            />
          </Box>
        </Box>
        <Box
          overflowY="auto"
          bg={colorMode === "dark" ? "gray.700" : "white"}
          height="81vh"
        >
          <Table variant="simple" size="md">
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th
                  borderLeft="1px solid"
                  borderColor={colorMode === "dark" ? "gray.600" : "gray.200"}
                >
                  Subject
                </Th>
              </Tr>
            </Thead>
            <Tbody overflowY="scroll">
              {emails.map((email, index) => (
                <Tr
                  cursor="pointer"
                  key={index}
                  onClick={() => email.id && handleSubjectClick(email.id)}
                >
                  <Td h="7vh" w="30%">
                    {email.sender}
                  </Td>
                  <Td
                    h="7vh"
                    w="70%"
                    borderLeft="1px solid"
                    borderColor={colorMode === "dark" ? "gray.600" : "gray.200"}
                  >
                    {email.subject}
                  </Td>
                </Tr>
              ))}
              {emails.length === 0 && (
                <Tr>
                  <Td colSpan={2} style={{ textAlign: "center" }}>
                    No emails to display
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <EmailThreadModal
        isOpen={isOpen}
        onClose={onClose}
        emailId={activeEmailId}
      />
    </Flex>
  );
}

export default Emails;
