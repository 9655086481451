import React, { useState, useEffect } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { useAuth, logout } from "../auth";
import {
  Box,
  Flex,
  Link,
  Skeleton,
  Image,
  IconButton,
  useDisclosure,
  Stack,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Button,
  Text,
  Divider,
  VStack,
  Tooltip,
  useColorMode,
  Collapse,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import logo from "../assets/images/NEXUSAIMS_dark_version.png";
import darkLogo from "../assets/images/NEXUSAIMS_light_version.png";
import "../styles/chat.css";
import { useUser } from "./userRole/UserContext";
import {
  SunIcon,
  MoonIcon,
  HamburgerIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
import "../styles/navbar.css";
import { FaRocket } from "react-icons/fa"; // Import the notification icon
import CurrentUserOrganization from "./CurrentUserOrganization";

const NavBar = () => {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const [logged] = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const username = localStorage.getItem("username")
    ? localStorage.getItem("username")
    : "Unknown User";
  const email = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : "unknown@example.com";

  const clearAgendaFromLocalStorage = () => {
    const storedKeys = JSON.parse(
      localStorage.getItem("agendaStorageKeys") || "[]"
    );
    storedKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
    localStorage.removeItem("agendaStorageKeys"); // Clear the keys array itself
  };

  function clearEditedAgendaPointsFromLocalStorage() {
    // Iterate over all keys in local storage
    for (let i = localStorage.length - 1; i >= 0; i--) {
      // We loop backward since the length will decrease as we remove items
      const key = localStorage.key(i);

      // Check if key starts with 'editedAgendaPoint-'
      if (key.startsWith("editedAgendaPoint-")) {
        localStorage.removeItem(key);
      }
    }
  }

  const clearAgendaFromLocalStorageDDQ_RFP = () => {
    const storedKeys = JSON.parse(
      localStorage.getItem("agendaStorageKeysDDQ_RFP") || "[]"
    );
    storedKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
    localStorage.removeItem("agendaStorageKeysDDQ_RFP"); // Clear the keys array itself
  };

  function clearEditedAgendaPointsFromLocalStorageDDQ_RFP() {
    // Iterate over all keys in local storage
    for (let i = localStorage.length - 1; i >= 0; i--) {
      // We loop backward since the length will decrease as we remove items
      const key = localStorage.key(i);

      // Check if key starts with 'editedAgendaPoint-'
      if (key.startsWith("editedAgendaPointDDQ_RFP-")) {
        localStorage.removeItem(key);
      }
    }
  }
  const logoutAndClear = () => {
    logout();
    clearAgendaFromLocalStorage();
    // Call this function upon logout
    clearEditedAgendaPointsFromLocalStorage();
    clearAgendaFromLocalStorageDDQ_RFP();
    // Call this function upon logout
    clearEditedAgendaPointsFromLocalStorageDDQ_RFP();

    const keysToRemove = [
      "chat_messages",
      "selectedAreaChat",
      "selectedModuleChat",
      "selectedAreaModuleChat",
      "selectedRole",
      "roles",
      "dropdownValue",
      "editedResponse",
      "selectedEmailId",
      "isEditing",
      "selectedText",
      "draftResponse",
      "modules",
      "selectedThread",
      "domains",
      "uploading",
      "selectedEmailIndex",
      "error",
      "selectedModule",
      "threads",
      "selectedThreadEmails",
      "selectedArea",
      "selectedModuleEmail",
      "user",
      "temperature",
      "model",
      "selectedModel",
      "topP",
      "frequencyPenalty",
      "presencePenalty",
      "maxTokens",
      "MANAGER_ORGANIZATION",
      "email",
      "username",
      "modulesUpload",
      "selectedModuleUpload",
      "showInputFieldUpload",
      "currentMinuteUpload",
      "moduleMinutesUpload",
      "selectedMinuteUpload",
      "companyNameUpload",
      "fundNameUpload",
      "corporateSecretaryUpload",
      "depositoryBankUpload",
      "fundAdministratorUpload",
      "fundAliasUpload",
      "endDateUpload",
      "isOutputReceivedUpload",
      "startDateUpload",
      "companyAliasUpload",
      "fundAuditorUpload",
      "investmentManagementEntityUpload",
      "directorsUpload",
      "currentDirectorUpload",
      "showInputDirectorUpload",
      "mainValuesUpload",
      "currentMainValueUpload",
      "showMainValueInputUpload",
      "subValuesUpload",
      "currentSubValueUpload",
      "showSubValueInputUpload",
      "headingsUpload",
      "submittedHeadingsUpload",
      "outputTextUpload",
      "headingOutputsUpload",
      "isUploading",
      "minutesListUpload",
      "selectedOrganization",
      "setIsLoadingUpload",
      "uploading",
      "selectedRoleChat",
      "deepSearch",
      "minutesData",
      "storageKey",
      "agendaStorageKeys",
      "agendaPointsUpload",
      "successfulGenerations",
      "successfulGenerateAll",
      "loadingGenerateAll",
      "loadingAgendas",
      "showInputFieldMinuteUpload",
      "agendaPoints",
      "modulesDDQ_RFP",
      "selectedModuleDDQ_RFP",
      "selectedRoleDDQ_RFP",
      "selectedMinutesIdDDQ_RFP",
      "agendaPointsDDQ_RFP",
      "successfulGenerateAllDDQ_RFP",
      "successfulGenerationsDDQ_RFP",
      "loadingAgendasDDQ_RFP",
      "loadingGenerateAllDDQ_RFP",
      "currentMinuteUploadDDQ_RFP",
      "minutesListUploadDDQ_RFP",
      "showInputFieldUploadDDQ_RFP",
      "showInputFieldMinuteUploadDDQ_RFP",
      "agendaStorageKeysDDQ_RFP",
      "editedAgendaPointDDQ_RFP",
      "selectedMinuteDDQ_RFP",
      "emailsDDQ_RFP",
      "selectedRoleEmail",
      "selectedDropdowns",
      "selectedTabIndex",
      "selectedOrganizationSettings",
      "fundsListUploadFund",
      "selectedFund",
      "agendaPointsFund",
      "showInputFieldUploadFund",
      "currentFundUploadFund",
      "campaigns",
      "sequences",
      "linked",
    ];

    keysToRemove.forEach((key) => localStorage.removeItem(key));

    history.push("/login");
  };

  // const getModuleLink = (moduleKey, path, displayName) => {
  //   const access = user && user.moduleAccess[moduleKey];
  //   switch (access) {
  //     case "allowed":
  //       return { to: path, text: displayName };
  //     case "please inquire":
  //       return {
  //         to: null, // No link destination
  //         text: (
  //           <Tooltip label="Please inquire" hasArrow>
  //             <span>{displayName}</span>
  //           </Tooltip>
  //         ),
  //       };
  //     case "coming soon":
  //       return {
  //         to: null, // No link destination
  //         text: (
  //           <Tooltip label="Coming Soon" hasArrow>
  //             <span>{displayName}</span>
  //           </Tooltip>
  //         ),
  //       };
  //     default:
  //       return { to: null, text: displayName }; // No access or undefined
  //   }
  // };

  
  const getModuleLink = (moduleKey, path, displayName) => {
    const access = user && user.moduleAccess[moduleKey];
    switch (access) {
      case "allowed":
        // if (displayName === "Market Master") {
        //   // Renders an <a> tag for "Market Master"
        //   return {
        //     to: null,
        //     text: (
        //       <a href={path} target="_blank" rel="noopener noreferrer">{displayName}</a>
        //     )
        //   };
        // } else {
          // Renders a <Link> component for other links
          return {
            to: path,
            text: <Link to={path}>{displayName}</Link>
          };
        //}
      case "please inquire":
        return {
          to: null, // No link destination
          text: (
            <Tooltip label="Please inquire" hasArrow>
              <span>{displayName}</span>
            </Tooltip>
          ),
        };
      case "coming soon":
        return {
          to: null, // No link destination
          text: (
            <Tooltip label="Coming Soon" hasArrow>
              <span>{displayName}</span>
            </Tooltip>
          ),
        };
      default:
        return { to: null, text: displayName }; // No access or undefined
    }
  };
  

  
  const { user, loading } = useUser();
  const role = user ? user.role : null;

  let navLinks = [];

  if (loading) {
    // If module access is not yet loaded, possibly due to an asynchronous fetch
    navLinks = [
      { to: "#", text: <Skeleton width="100px" height="20px" /> },
      { to: "#", text: <Skeleton width="100px" height="20px" /> },
      { to: "#", text: <Skeleton width="100px" height="20px" /> },
      { to: "#", text: <Skeleton width="100px" height="20px" /> },
      { to: "#", text: <Skeleton width="100px" height="20px" /> },
      { to: "#", text: <Skeleton width="100px" height="20px" /> },
      { to: "#", text: <Skeleton width="100px" height="20px" /> },
      //... add more skeletons if needed// Add skeleton or placeholders if necessary
    ];
  } else if (logged && user && user.moduleAccess) {
    navLinks = [
      // { to: "/", text: "Home" },
      getModuleLink("home", "/", "Home"),
      getModuleLink("nexus-genie", "/chat", "NexusGenie"),
      getModuleLink("minute-master", "/minutes", "Minute Master"),
      getModuleLink("ddq-master", "/ddq_rfp", "DDQ Master"),
      getModuleLink("fund-docs", "/fund_docs", "PitchComply"),
      getModuleLink("campaign", "/campaign", "Market Master",),
      getModuleLink("nexus-scribe", null, "NexusScribe"), // Example with no direct path
      // getModuleLink("active-agent", null, "Market Master"),
    ];
  } else {
    navLinks = [{ to: "/login", text: "Login" }];
  }

  const popoverLinks = logged
    ? [
        { to: "/apps", text: "Apps" },
        // { to: "/credentials", text: "Credentials" },
        ...(role === "Admin" || role === "Manager"
          ? [{ to: "/credentials", text: "Credentials" }]
          : []),
        ...(role === "Admin" ? [{ to: "/admin_panel", text: "Users" }] : []),
        ...(role === "Manager"
          ? [{ to: "/manager_panel", text: "Users" }]
          : []),
        ...(role === "Admin"
          ? [{ to: "/website_settings", text: "Website Settings" }]
          : []),
        // ...(role === "Admin" ? [{ to: "/admin_panel", text: "Settings" }] : []),
      ]
    : [];

  const ColorModeSwitcher = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    return (
      <IconButton
        aria-label="Toggle dark mode"
        icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
        onClick={toggleColorMode}
        size="sm"
        mr={2} // some margin to separate from other items, adjust as needed
      />
    );
  };

  return (
    <Box
      bg={colorMode === "light" ? "white" : "#171923"}
      w="100%"
      p={2}
      color={colorMode === "light" ? "black" : "white"}
      borderBottom={
        colorMode === "light"
          ? "1px solid rgba(0, 0, 0, 0.1)"
          : "1px solid rgba(255, 255, 255, 0.1)"
      }
    >
      <Flex h="35px" alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          display={{ base: "block", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="Open Menu"
          icon={<HamburgerIcon />}
        />
        <Link as={RouterLink} to="/">
          <Image
            src={colorMode === "light" ? logo : darkLogo}
            alt="logo"
            width="100px"
            height="auto"
            objectFit="cover"
            pl={0}
            pt={1}
          />
        </Link>
        {/*without moving bar */}

        <Flex
          as="nav"
          justify="space-between"
          alignItems="center"
          display={{ base: "none", md: "flex" }}
          flex="1"
          pl={14}
          position="relative"
        >
          <Box position="absolute" left="0" right="0">
            <Stack
              spacing={4}
              direction="row"
              justify="center"
              // paddingLeft={4}
            >
              {navLinks.map((link, index) =>
                link.to ? (
                  <Link as={RouterLink} key={index} to={link.to}>
                    {link.text}
                  </Link>
                ) : (
                  // Render as a non-interactive element but also add Tooltip if there is additional text or info
                  <Tooltip label={link.tooltip} hasArrow key={index}>
                    <Box>{link.text}</Box>
                  </Tooltip>
                )
              )}
            </Stack>
          </Box>
        </Flex>

        {logged && (
          <Flex align="center">
            {/* Notification Icon with Link */}
            {role === "Admin" && <CurrentUserOrganization />}
            <Text mt={4} mr={2} fontWeight="semibold">
              v2.1
            </Text>
            <Link as={RouterLink} to="/notifications" mr={2}>
              <Tooltip label="Next Update" hasArrow>
                <IconButton
                  icon={<FaRocket />}
                  aria-label="Notifications"
                  // variant="ghost"
                  size="sm"
                />
              </Tooltip>
            </Link>
            <ColorModeSwitcher mr={2} />{" "}
            <Popover>
              <PopoverTrigger>
                <Avatar
                  name={username}
                  src="https://bit.ly/broken-link"
                  cursor="pointer"
                  size="sm"
                />
              </PopoverTrigger>
              <PopoverContent
                bg={colorMode === "light" ? "white" : "gray.700"}
                borderRadius="16px"
                borderColor={colorMode === "light" ? "gray.200" : "gray.600"}
                boxShadow="2xl"
                w="350px"
                overflowY="auto"
                right={2}
                color={colorMode === "light" ? "gray.800" : "gray.200"}
                p={5}
              >
                <PopoverArrow />
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  bg={colorMode === "light" ? "gray.50" : "gray.800"}
                  p={4}
                  borderRadius="md"
                  color={colorMode === "light" ? "gray.800" : "gray.200"}
                >
                  <Box>
                    <Text fontWeight="bold" mb={1}>
                      {username}
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      {email}
                    </Text>
                    <Text fontSize="xs" color="gray.400">
                      Accounts
                    </Text>
                  </Box>
                  <Avatar
                    name={username}
                    src="https://bit.ly/broken-link"
                    size="lg"
                  />
                </Flex>
                <Divider my={4} borderColor="gray.200" />
                <VStack align="start" spacing={2}>
                  {popoverLinks.map((link, index) => (
                    <Link
                      as={RouterLink}
                      key={index}
                      to={link.to}
                      p={2}
                      fontSize="sm"
                      fontWeight="semibold"
                      color={colorMode === "light" ? "gray.800" : "gray.200"}
                      w="full"
                      textAlign="left"
                      bgColor={colorMode === "light" ? "gray.50" : "gray.800"}
                      borderRadius="md"
                      _hover={{ bgColor: "blue.100", color: "blue.800" }}
                    >
                      {link.text}
                    </Link>
                  ))}
                </VStack>
                <Divider my={4} borderColor="gray.200" />
                <Button
                  variant="solid"
                  colorScheme="blue"
                  w="full"
                  onClick={logoutAndClear}
                >
                  Logout
                </Button>
              </PopoverContent>
            </Popover>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
export default NavBar;
