import React, { useState, useEffect } from "react";
import axiosInstance from "../../helpers/axiosInstance";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  VStack,
  useToast,
  Flex,
  Heading,
  useColorMode,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import ReactQuill from "react-quill"; // Import ReactQuill
import "react-quill/dist/quill.snow.css"; // Import quill styles
import Sidebar from "./Sidebar";
import { DeleteIcon } from "@chakra-ui/icons";

function Signature() {
  const { colorMode } = useColorMode();
  const [signatures, setSignatures] = useState([]);
  const [newSignature, setNewSignature] = useState("");
  const toast = useToast();

  useEffect(() => {
    fetchAllSignatures();
  }, []);

  const fetchAllSignatures = async () => {
    try {
      const response = await axiosInstance.get("/signature/fetch-signature");
      if (response.data.success) {
        setSignatures(response.data.data);
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching signatures:", error);
      toast({
        title: "Error",
        description: "An error occurred while fetching signatures.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const createSignature = async () => {
    const processedSignature = "<p><br></p>" + newSignature.replace(/<p>/g, '<p style="margin: 0;">');
    try {
      const response = await axiosInstance.post("/signature/create", {
        signature_text: processedSignature,
      });
      if (response.status === 201) {
        fetchAllSignatures();
        setNewSignature("");
        toast({
          title: "Success",
          description: "Signature created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setNewSignature("");
      }
    } catch (error) {
      console.error("Error creating signature:", error);
      toast({
        title: "Error",
        description: "An error occurred while creating the signature.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setNewSignature("");
    }
  };

  const deleteSignature = async (id) => {
    try {
      const response = await axiosInstance.delete(`/signature/${id}`);
      if (response.data.success) {
        fetchAllSignatures();
        toast({
          title: "Success",
          description: "Signature deleted successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error deleting signature:", error);
      toast({
        title: "Error",
        description: "An error occurred while deleting the signature.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex>
      <Sidebar />
      <Box flex="1" bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}>
        <Box
          p={5}
          pl={6}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          h="10.7vh"
          borderBottom="1px"
          borderColor="blackAlpha.200"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="lg" fontWeight="semibold" mt={4} m={0}>
            Signature Management
          </Heading>
        </Box>
        <Box
          as="section"
          m="2"
          h="86.5vh"
          p={4}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          borderRadius="lg"
          border="1px"
          borderColor="blackAlpha.200"
        >
          <VStack spacing={4} align="stretch">
            <Box>
              <FormControl>
                <FormLabel>Create Signature</FormLabel>
                <VStack spacing={8} align="stretch" width="35%">
                  <Box mb={6}>
                    <ReactQuill
                      theme="snow"
                      value={newSignature}
                      onChange={setNewSignature}
                      style={{
                        height: "25vh",
                      }}
                    />
                  </Box>
                  <Button
                    mt={4}
                    mb={0}
                    onClick={createSignature}
                    colorScheme="blue"
                    width="25%"
                    size="sm"
                  >
                    Create
                  </Button>
                </VStack>
              </FormControl>
            </Box>

            <Box width="35%" h="25%" mb={2}>
              <Heading size="sm" fontWeight="semibold">
                Available Signatures
              </Heading>
              {signatures.map((signature) => (
                <Flex
                  key={signature._id}
                  p={2}
                  pr={0}
                  borderWidth={1}
                  borderRadius="md"
                  mt={2}
                  justify="space-between"
                  align="center"
                  h="27vh"
                >
                  <Flex h="100%" w="100%" m={2} mr={0} overflowY="scroll">
                    <Text
                      flex="1"
                      h="100%"
                      w="100%"
                      dangerouslySetInnerHTML={{
                        __html: signature.signature_text,
                      }}
                    />
                    <IconButton
                      variant="ghost"
                      icon={<DeleteIcon />}
                      color={colorMode === "dark" ? "white" : "black"}
                      onClick={() => deleteSignature(signature._id)}
                      size="xs"
                    />
                  </Flex>
                </Flex>
              ))}
            </Box>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
}

export default Signature;
