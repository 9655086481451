import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { Button, useToast } from "@chakra-ui/react";
import { logout } from "../../auth";
import { useColorMode } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";

const FileDownload = ({ uploading, selectedFundsId }) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const handleDownload = async () => {
    try {
      const response = await axiosInstance.post(
        "/fund_docs/download-report",
        { str_id: selectedFundsId },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob", // Set responseType to "blob" to handle binary data
        }
      );

      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'downloaded_file.docx'; // Default filename if not specified
      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      saveAs(response.data, filename);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
        toast({
          title: "Session expired",
          description: "Your session has expired. Please login again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error("Download error:", error);
        toast({
          title: "Download failed",
          description: "An error occurred while downloading the file.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Button
      bgColor={colorMode === 'light' ? 'blue.500' : 'blue.200'}
      textColor={colorMode === 'light' ? 'white' : 'black'}
      w="100%"
      onClick={handleDownload}
      isDisabled={uploading}
      mt={2}
      p={3}
    >
      Download
    </Button>
  );
};

export default FileDownload;






// import React from "react";
// import axios from "axios";
// import { saveAs } from "file-saver";
// import { Button, useToast } from "@chakra-ui/react";
// import { logout } from "../../auth";
// import { useColorMode } from "@chakra-ui/react";
// // import '../styles/fileHandlingPage.css';
// import axiosInstance from "../../helpers/axiosInstance";

// const FileDownload = ({ uploading, selectedFundsId }) => {
//   const { colorMode } = useColorMode();
//   const toast = useToast();
//   const handleDownload = async () => {
//    // console.log("Downloading function", selectedFundsId);
//     try {
//       const response = await axiosInstance.post(
//         "/fund_docs/download-report",
//         { str_id: selectedFundsId },
//         {
//           headers: {
//             "Content-Type":
//               "application/json", // Overrides the default Content-Type
//           },
//           responseType: "blob", // Set responseType to "blob" to handle binary data
//         }
//       );
//       console.log("file name",response.data)

//       saveAs(response.data, `output.docx`);
//     } catch (error) {
//       if (error.response && error.response.status === 401) {
//         logout();
//         toast({
//           title: "Session expired",
//           description: "Your session has expired. Please login again.",
//           status: "error",
//           duration: 5000,
//           isClosable: true,
//         });
//       } else {
//         console.error(error);
//       }
//     }
//   };

//   return (
//     <Button
//       _hover={{}}
//       bgColor="blue.500"
//       textColor="white"
//       w="100%"
//       onClick={handleDownload}
//       isDisabled={uploading}
//       mt={2}
//       p={3}
//     >
//       Download
//     </Button>
//   );
// };

// export default FileDownload;