import React, { useState, useEffect } from "react";
import { Box, Tab, TabList, Tabs, useColorMode } from "@chakra-ui/react";
import UpcomingUpdates from "./UpcomingUpdates";
import UpdateOrganization from "./UpdateOrganization";
import CreateOrganization from "./CreateOrganization";

const WebsiteSettings = () => {
  const { colorMode } = useColorMode();
  const [tabIndex, setTabIndex] = useState(
    parseInt(localStorage.getItem("selectedTabIndex")) || 0
  );

  const handleTabsChange = (index) => {
    setTabIndex(index);
    localStorage.setItem("selectedTabIndex", index.toString()); // Store the selected tab index in localStorage
  };

  return (
    <Box
      minHeight="calc(100vh - 52px)"
      maxHeight="calc(100vh - 52px)"
      display="flex"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
      overflow="hidden"
    >
      {/* Left Box with Tabs */}
      <Box
        flex="2"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        display="flex"
        flexDirection="column"
        maxHeight="calc(100vh - 51px - 8px - 8px)"
        minHeight="calc(100vh - 51px - 8px - 8px)"
        minW="calc(20% + 8px)"
        mr={0}
        mt={2}
        ml={2}
        mb={2}
        borderRadius="lg"
        p={4}
      >
        <Tabs
          onChange={handleTabsChange}
          orientation="vertical"
          index={tabIndex} // Set the selected tab index based on the state
        >
          <TabList align="left">
            <Tab>Upcoming Updates</Tab>
            <Tab>Update Organization</Tab>
            <Tab>Create Organization</Tab>
            {/* Add more tabs as needed */}
          </TabList>
        </Tabs>
      </Box>

      {/* Middle Box for Content */}
      <Box
        flex="8"
        maxW="calc(80% - 32px)"
        minW="calc(80% - 32px)"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        color={colorMode === "dark" ? "white" : "black"}
        display="flex"
        flexDirection="column"
        mr={2}
        mt={2}
        ml={2}
        mb={2}
        borderRadius="lg"
        p={4}
        overflowY="scroll"
        scrollbarWidth="0px"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {tabIndex === 0 && <UpcomingUpdates />}
        {tabIndex === 1 && <UpdateOrganization />}
        {tabIndex === 2 && <CreateOrganization />}
        {/* Add conditional rendering for additional tabs */}
      </Box>
    </Box>
  );
};

export default WebsiteSettings;
