import React, { useState, useEffect } from "react";
import { Box, Select, Button, useToast } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance"; // Import your Axios instance
import usePersistedState from "./../usePersistedState";

const UpdateOrganization = () => {
  const [organizations, setOrganizations] = useState([]);
  //const [selectedOrganization, setSelectedOrganization] = useState(""); // State to store the selected organization
  const [selectedOrganization, setSelectedOrganization] = usePersistedState(
    "selectedOrganizationSettings",
    ""
  );
  const toast = useToast();

  const fetchOrganizations = () => {
    axiosInstance.get("/org/get_organizations").then((response) => {
      if (Array.isArray(response.data.organizations)) {
        setOrganizations(response.data.organizations);
        console.log("Organization list", response.data.organizations);
      } else {
        console.error(
          "API did not return an array in 'organizations' key:",
          response.data
        );
        // Handle this case as needed, maybe set organizations to an empty array
        setOrganizations([]);
      }
    });
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  // Function to handle the organization selection and send it to the backend
  const handleOrganizationSelect = () => {
    if (selectedOrganization) {
      // Send selectedOrganization to the backend using a POST request
      console.log("Selected Organization payload", selectedOrganization);
      axiosInstance
        .post("/website_settings/update-organization", {
          new_organization: selectedOrganization,
        })

        .then((response) => {
          // Handle the response from the backend as needed
          console.log("Organization updated successfully:", response.data);
          // localStorage.removeItem("campaigns");
          // localStorage.removeItem("chat_messages");
          // localStorage.removeItem("selectedModuleChat");
          // localStorage.removeItem("selectedRoleChat");
          // localStorage.removeItem("selectedModel");
          // localStorage.removeItem("deepSearch");
          const keysToRemove = [
            "chat_messages",
            "selectedAreaChat",
            "selectedModuleChat",
            "selectedAreaModuleChat",
            "selectedRole",
            "roles",
            "dropdownValue",
            "editedResponse",
            "selectedEmailId",
            "isEditing",
            "selectedText",
            "draftResponse",
            "modules",
            "selectedThread",
            "domains",
            "uploading",
            "selectedEmailIndex",
            "error",
            "selectedModule",
            "threads",
            "selectedThreadEmails",
            "selectedArea",
            "selectedModuleEmail",
            "user",
            "temperature",
            "model",
            "selectedModel",
            "topP",
            "frequencyPenalty",
            "presencePenalty",
            "maxTokens",
            "MANAGER_ORGANIZATION",
            "modulesUpload",
            "selectedModuleUpload",
            "showInputFieldUpload",
            "currentMinuteUpload",
            "moduleMinutesUpload",
            "selectedMinuteUpload",
            "companyNameUpload",
            "fundNameUpload",
            "corporateSecretaryUpload",
            "depositoryBankUpload",
            "fundAdministratorUpload",
            "fundAliasUpload",
            "endDateUpload",
            "isOutputReceivedUpload",
            "startDateUpload",
            "companyAliasUpload",
            "fundAuditorUpload",
            "investmentManagementEntityUpload",
            "directorsUpload",
            "currentDirectorUpload",
            "showInputDirectorUpload",
            "mainValuesUpload",
            "currentMainValueUpload",
            "showMainValueInputUpload",
            "subValuesUpload",
            "currentSubValueUpload",
            "showSubValueInputUpload",
            "headingsUpload",
            "submittedHeadingsUpload",
            "outputTextUpload",
            "headingOutputsUpload",
            "isUploading",
            "minutesListUpload",
            "setIsLoadingUpload",
            "uploading",
            "selectedRoleChat",
            "deepSearch",
            "minutesData",
            "storageKey",
            "agendaStorageKeys",
            "agendaPointsUpload",
            "successfulGenerations",
            "successfulGenerateAll",
            "loadingGenerateAll",
            "loadingAgendas",
            "showInputFieldMinuteUpload",
            "agendaPoints",
            "modulesDDQ_RFP",
            "selectedModuleDDQ_RFP",
            "selectedRoleDDQ_RFP",
            "selectedMinutesIdDDQ_RFP",
            "agendaPointsDDQ_RFP",
            "successfulGenerateAllDDQ_RFP",
            "successfulGenerationsDDQ_RFP",
            "loadingAgendasDDQ_RFP",
            "loadingGenerateAllDDQ_RFP",
            "currentMinuteUploadDDQ_RFP",
            "minutesListUploadDDQ_RFP",
            "showInputFieldUploadDDQ_RFP",
            "showInputFieldMinuteUploadDDQ_RFP",
            "agendaStorageKeysDDQ_RFP",
            "editedAgendaPointDDQ_RFP",
            "selectedMinuteDDQ_RFP",
            "emailsDDQ_RFP",
            "selectedRoleEmail",
            "selectedDropdowns",
            "selectedTabIndex",
            "fundsListUploadFund",
            "selectedFund",
            "agendaPointsFund",
            "showInputFieldUploadFund",
            "currentFundUploadFund",
            "campaigns",
            "sequences",
            "linked",
          ];
      
          keysToRemove.forEach((key) => localStorage.removeItem(key));
      
          // Reload the page after a successful response
          window.location.reload();
          toast({
            title: "Organization Updated",
            description: "Organization name updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          // Handle errors if the POST request fails
          console.error("Error updating organization:", error);
        });
    } else {
      // Handle the case where no organization is selected
      console.error("Please select an organization.");
    }
  };

  return (
    <Box>
      <Select
        placeholder="Select an organization"
        value={selectedOrganization} // Set the selected organization as the value
        onChange={(e) => setSelectedOrganization(e.target.value)}
        w="30%"
      >
        {organizations.map((org, index) => (
          <option key={index} value={org}>
            {org}
          </option>
        ))}
      </Select>
      <Button colorScheme="blue" mt={2} onClick={handleOrganizationSelect}>
        Update Organization
      </Button>
    </Box>
  );
};

export default UpdateOrganization;
