import React, { useState, useEffect } from "react";
import { Box, FormControl, FormLabel, Button, useToast } from "@chakra-ui/react";
import { logout } from "../../auth";
import { loadCurrentMessage } from "./FetchMessage";
import axiosInstance from "../../helpers/axiosInstance";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const API_ENDPOINT = "/website_settings/currentMessage";

// Centralize the API calls
const api = {
  getCurrentMessage: async () => {
    return axiosInstance.get(API_ENDPOINT);
  },
  updateCurrentMessage: async (message) => {
    return axiosInstance.post(API_ENDPOINT, { message });
  },
};

const UpcomingUpdates = () => {
  const [newMessage, setNewMessage] = useState("");
  const [currentMessage, setCurrentMessage] = useState("");
  const toast = useToast();

  useEffect(() => {
    const fetchMessage = async () => {
      try {
        const data = await loadCurrentMessage();
        setCurrentMessage(data);
      } catch (error) {
        handleSessionError(error);
      }
    };

    fetchMessage();
  }, []);

  const handleSessionError = (error) => {
    if (error.response && error.response.status === 401) {
      logout();
      displaySessionExpiredToast();
    }
  };

  const displaySessionExpiredToast = () => {
    toast({
      title: "Session expired",
      description: "Your session has expired. Please login again.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const handleUpdate = async () => {
    try {
      const response = await api.updateCurrentMessage(newMessage);

      if (response.status === 200) {
        setCurrentMessage(newMessage);
        setNewMessage("");
        toast({
          title: "Update Successful",
          description: "The message has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating the message:", error);
    }
  };

  return (
    <>
      <FormControl>
        <FormLabel>Current Message</FormLabel>
        <Box
          h="30vh"
          overflowY="auto"
          mb={4}
          p={2}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
        >
          <Box dangerouslySetInnerHTML={{ __html: currentMessage }} />
        </Box>

        <FormLabel>Update the Message</FormLabel>
        <ReactQuill value={newMessage} onChange={setNewMessage} />

        <Button
          colorScheme="blue"
          bgColor="blue.500"
          textColor="white"
          onClick={handleUpdate}
          mt={4}
        >
          Update the Message
        </Button>
      </FormControl>
    </>
  );
};

export default UpcomingUpdates;