import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Badge,
  Text,
  Divider,
  useColorMode,
} from "@chakra-ui/react";
import axiosInstance from "../../../helpers/axiosInstance";

const EmailThreadModal = ({ isOpen, onClose, emailId }) => {
  const [threads, setThreads] = useState([]);
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (emailId) {
      axiosInstance
        .get(`/threads/conversation/${emailId}`)
        .then((response) => {
          console.log("modal reponse", response.data.data.threads);
          // Assuming the backend sends threads as an array of {emailContent, status} objects
          setThreads(response.data.data.threads);
        })
        .catch((error) => {
          console.error("Error fetching email threads:", error);
          setThreads([]); // Reset threads on error
        });
    }
  }, [emailId, isOpen]); // Fetch threads when emailId or isOpen changes

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        sx={{
          width: "70%", // Sets the width to 70% of the viewport width
          maxWidth: "none", // Removes any maximum width constraints
          height: "78vh", // Sets the height to auto
        }}
      >
        <ModalHeader>Email Thread</ModalHeader>
        <Divider m={0} p={0} />
        <ModalCloseButton />
        <ModalBody>
          <Box
            h="66vh"
            overflowY="scroll"
            scrollBehavior="hidden"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            {threads.map((thread, index) => (
              <Box
                h="40vh"
                key={index}
                p={4}
                // boxShadow="lg"
                // borderRadius="lg"
                bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
                color={colorMode === "dark" ? "white" : "black"}
                borderWidth={1}
                    borderRadius="md"
                mb={3}
                position="relative"
                overflowY="scroll"
                scrollBehavior="hidden"
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
              >
                <Badge
                  colorScheme={thread.status === "received" ? "green" : "blue"}
                  position="absolute"
                  top={1}
                  right={1}
                >
                  {thread.status}
                </Badge>
                <Text whiteSpace="pre-wrap">{thread.emailContent}</Text>
              </Box>
            ))}
            {threads.length === 0 && <Text>No messages found.</Text>}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EmailThreadModal;
