import React from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Checkbox,
  Flex,
  Button,
  Select,
  Tooltip,
  Textarea,
} from "@chakra-ui/react";
import { InfoOutlineIcon, InfoIcon } from "@chakra-ui/icons";
import ModulesDropdown from "../common/ModulesDropdown";
import RoleDropdown from "../common/RoleDropdown";
import EmailDropdown from "../common/EmailDropdown";

const CampaignModal = ({
  isOpen,
  onClose,
  handleAddCampaign,
  selectedModule,
  setSelectedModule,
  selectedRole,
  setSelectedRole,
  dropdownOptions,
  handleDropdownChange,
  emailRate,
  setEmailRate,
  inputError,
  setInputError,
  handleChange,
  selectedEmail,
  setSelectedEmail,
  frequency,
  setFrequency,
  lastEmail,
  setLastEmail,
  handleFrequencyChange,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Create a New Campaign</ModalHeader>
      <ModalCloseButton />
      <Box
        h="68vh"
        overflowY="scroll"
        scrollBehavior="hidden"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <form onSubmit={handleAddCampaign}>
          <ModalBody pb={6}>
            <FormControl isRequired>
              <FormLabel fontSize="sm">Campaign Name</FormLabel>
              <Input
                id="campaignName"
                placeholder="Enter campaign name"
                name="campaignName"
                size="sm"
                borderRadius="md"
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel fontSize="sm">Select Agent</FormLabel>
              <ModulesDropdown
                selectedModule={selectedModule}
                setSelectedModule={setSelectedModule}
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel fontSize="sm">Select Persona</FormLabel>
              <RoleDropdown
                selectedModule={selectedModule}
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
                size="sm"
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel fontSize="sm">Select Email</FormLabel>
              <EmailDropdown
                selectedEmail={selectedEmail}
                setSelectedEmail={setSelectedEmail}
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel fontSize="sm">Mission Statement</FormLabel>
              <Textarea
                id="missionStatement"
                placeholder="Enter mission statement"
                name="missionStatement"
                size="sm"
                borderRadius="md"
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel fontSize="sm" width="100%">
                Emails per hour (Recommended value: 30)
              </FormLabel>
              <Tooltip
                hasArrow
                label={inputError ? inputError : "Recommended value: 30"}
                isDisabled={!inputError}
                shouldWrapChildren
                mt="1"
              >
                <Box>
                  <Input
                    id="emailsPerHour"
                    placeholder="Enter no of emails"
                    name="emailsPerHour"
                    size="sm"
                    borderRadius="md"
                    width="215%" // Ensure Input takes full width
                    type="number"
                    value={emailRate}
                    onChange={handleChange}
                    borderColor={inputError ? "red.500" : "gray.200"}
                  />
                </Box>
              </Tooltip>
              {inputError && (
                <FormLabel fontSize="sm" color="red.500">
                  {inputError}
                </FormLabel>
              )}
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel fontSize="sm">Select File</FormLabel>
              <Select
                placeholder="Select data"
                onChange={handleDropdownChange}
                size="sm"
              >
                {dropdownOptions.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.filename}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel fontSize="sm">Frequency</FormLabel>
              <Select
                placeholder="Select frequency"
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
                size="sm"
              >
                <option value="3 minutes">3 minutes</option>
                <option value="30 minutes">30 minutes</option>
                <option value="60 minutes">60 minutes</option>
                <option value="2 hours">2 hours</option>
                <option value="3 hours">3 hours</option>
                <option value="5 hours">5 hours</option>
              </Select>
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel fontSize="sm">
                Review Emails
                <Tooltip
                  label="Number of emails the agent needs to review in order to generate more accurate emails."
                  aria-label="A tooltip"
                  bgColor="white"
                  textColor="black"
                  borderColor="gray.500"
                  border="1px solid"
                  borderRadius="md"
                >
                  <span>
                    <InfoOutlineIcon
                      color="gray.500"
                      ml={2}
                      cursor="pointer"
                      mb={1}
                      boxSize="12px"
                    />
                  </span>
                </Tooltip>
              </FormLabel>
              <Input
                placeholder="Enter the number"
                value={lastEmail}
                onChange={handleFrequencyChange}
                size="sm"
                type="number"
              />
            </FormControl>

            <FormControl mt={4}>
              <Flex alignItems="center">
                <Checkbox
                  id="isActive" // Associate the checkbox with a label using id
                  name="isActive"
                  size="sm"
                  mb={2}
                />
                {/* Wrap the text part of the label in a label element and associate it with the checkbox */}
                <FormLabel fontSize="sm" ml={2}>
                  Active Agent
                  <Tooltip
                    label="When the active agent is enabled, AI-generated emails are sent directly to the leads. Otherwise, drafts are created."
                    aria-label="A tooltip"
                    bgColor="white"
                    textColor="black"
                    borderColor="gray.500"
                    border="1px solid"
                    borderRadius="md"
                  >
                    <span>
                      <InfoOutlineIcon
                        color="gray.500" // Adjust this color to make the icon visible
                        ml={2}
                        cursor="pointer"
                        mb={1}
                        boxSize="12px"
                        onClick={(e) => e.stopPropagation()}
                      />
                    </span>
                  </Tooltip>
                </FormLabel>
              </Flex>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} type="submit">
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </form>
      </Box>
    </ModalContent>
  </Modal>
);

export default CampaignModal;
