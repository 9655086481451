import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Flex,
  Text,
  Box,
  Heading,
  Button,
  useColorMode,
  useDisclosure,
  Switch,
  VStack,
  Icon,
  Divider,
  Spacer,
  useToast,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { AddIcon, RepeatIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import usePersistedState from "../../usePersistedState";
import Sidebar from "../Sidebar";
import SequenceModal from "./SequenceModal";
import EditSequenceModal from "./EditSequenceModal";
import axiosInstance from "../../../helpers/axiosInstance";

function decodeCampaignData(campaignData) {
  if (!campaignData) {
    return { campaignId: null, isFromBackend: false };
  }
  const [id, flag] = campaignData.split(":");
  return { campaignId: id, isFromBackend: flag === "true" };
}

function Sequences() {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [campaigns, setCampaigns] = usePersistedState("campaigns", []);
  const { campaignData } = useParams();
  const { campaignId, isFromBackend } = decodeCampaignData(campaignData);
  const [delayNumber, setDelayNumber] = useState("");
  const [delayUnit, setDelayUnit] = useState("m");
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [sequenceCount, setSequenceCount] = useState(0);
  const [signature, setSignature] = useState("");
  const [isUpdating, setIsUpdating] = useState(false); // State to track if update is in progress
  const [currentCampaignId, setCurrentCampaignId] = useState(null);
  const [currentSequenceIndex, setCurrentSequenceIndex] = useState(null);

  const [formData, setFormData] = useState({
    sequence_name: "",
    delay_time: "",
    subject: "",
    sequence_template: "",
    is_active: false,
    signature: false,
  });

  const currentCampaign = campaigns.find(
    (campaign) => campaign.campaign_id === campaignId
  );

  const initialFormData = {
    sequence_name: "",
    delay_time: "",
    subject: "",
    sequence_template: "",
    is_active: false,
    signature: false,
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  // When opening the modal to add a new sequence
  const openAddModal = () => {
    resetFormData();
    if (currentCampaign) {
      setSequenceCount(currentCampaign.sequences.length);
      console.log(
        "currentCampaign sequence length",
        currentCampaign.sequences.length
      );
    }
    onOpen();
  };

  useEffect(() => {
    if (isFromBackend) {
      fetchCampaignDetails(campaignId);
    }
  }, [campaignId, isFromBackend]);

  const fetchCampaignDetails = async (id) => {
    try {
      const response = await axiosInstance.get(
        `/campaign/get-campaign-by-id/${id}`
      );
      if (response.data && response.data.success) {
        const fetchedCampaign = response.data.data;
        const updatedCampaign = {
          ...fetchedCampaign,
          campaign_id: fetchedCampaign._id,
          sequences: fetchedCampaign.sequence_list.map((seq) => ({
            ...seq,
            sequence_insight: seq.sequence_insight || {}, // Ensure sequence_insight exists
          })),
          isFromBackend: true,
        };

        setCampaigns((prevCampaigns) => {
          let updatedCampaigns;
          const existingCampaign = prevCampaigns.find(
            (campaign) => campaign.campaign_id === id
          );
          if (existingCampaign) {
            updatedCampaigns = prevCampaigns.map((campaign) => {
              if (campaign.campaign_id === id) {
                return updatedCampaign;
              }
              return campaign;
            });
          } else {
            updatedCampaigns = [...prevCampaigns, updatedCampaign];
          }

          // Update local storage
          localStorage.setItem("campaigns", JSON.stringify(updatedCampaigns));
          // console.log(
          //   "fetchCampaignDetails updatedCampaigns",
          //   updatedCampaigns
          // );
          return updatedCampaigns;
        });
      } else {
        toast({
          title: "Error fetching campaign",
          description: response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching campaign details:", error);
      toast({
        title: "Error",
        description: "An error occurred while fetching campaign details.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const UpdateCampaignInsights = async (id) => {
    try {
      const response = await axiosInstance.get(`/campaign/get-insghts/${id}`);
      if (response.data && response.data.success) {
        const fetchedInsights = response.data.data;
        //console.log("response", response.data.data);
        setCampaigns((prevCampaigns) => {
          return prevCampaigns.map((campaign) => {
            if (campaign.campaign_id === id) {
              const updatedSequences = campaign.sequences.map((seq) => {
                const matchingInsight = fetchedInsights.find(
                  (insight) => insight.sequence_name === seq.sequence_name
                );
                return matchingInsight
                  ? {
                      ...seq,
                      sequence_insight: {
                        emails_sent: matchingInsight.emails_sent,
                        opened_count: matchingInsight.opened_count,
                        replied_count: matchingInsight.replied_count,
                        scheduled_emails: matchingInsight.scheduled_emails,
                      },
                    }
                  : seq;
              });
              return { ...campaign, sequences: updatedSequences };
            }
            return campaign;
          });
        });
      } else {
        toast({
          title: "Error fetching campaign insights",
          description: response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching campaign insights:", error);
      toast({
        title: "Error",
        description: "An error occurred while fetching campaign insights.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fetch signature
  useEffect(() => {
    const fetchSignature = async () => {
      try {
        const response = await axiosInstance.get("/signature/fetch-signature");
        if (response.data.success) {
          const fetchedSignature = response.data.data[0].signature_text; // Assuming you want the first signature
          setSignature(fetchedSignature);
        } else {
          console.error("Error fetching signature:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching signature:", error);
      }
    };

    fetchSignature();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Function to handle adding a new sequence to the specific campaign
  const handleAddSequence = () => {
    // Check if any of the required fields are empty or not provided
    if (
      !formData.sequence_name ||
      !formData.delay_time ||
      !formData.sequence_template ||
      (sequenceCount < 1 && !formData.subject)
    ) {
      // Handle the error: Alert, throw an error, or another form of notification
      toast({
        title: "Fields",
        description: "All fields are required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Exit the function if there is an error
    }
    const modifiedSequenceTemplate = formData.sequence_template.replace(
      /<p>/g,
      '<p style="margin: 0;">'
    );

    const newSequence = {
      ...formData,
      sequence_template: modifiedSequenceTemplate, // Convert to plain text
      subject: convertHtmlToPlainText(formData.subject), // Convert to plain text
    };

    // Update the specific campaign with new sequence
    const updatedCampaigns = campaigns.map((campaign) => {
      if (campaign.campaign_id === campaignId) {
        return { ...campaign, sequences: [...campaign.sequences, newSequence] };
      }
      return campaign;
    });

    setCampaigns(updatedCampaigns);
    // Reset form data to initial state
    setFormData({
      sequence_name: "",
      delay_time: "",
      subject: "",
      sequence_template: "",
      is_active: false,
      signature: false,
    });
    onClose();
  };

  const convertHtmlToPlainText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const handleSaveCampaign = async (campaignId) => {
    // Retrieve all campaigns from local storage
    const storedCampaigns = localStorage.getItem("campaigns");
    let campaigns = storedCampaigns ? JSON.parse(storedCampaigns) : [];

    // Find the specific campaign by ID
    const currentCampaign = campaigns.find(
      (campaign) => campaign.campaign_id === campaignId
    );

    if (!currentCampaign) {
      console.error("Campaign not found");
      return; // Exit the function if no campaign is found
    }

    // Construct the payload with the current campaign data
    const campaignData = {
      campaign_name: currentCampaign.campaign_name,
      module_name: currentCampaign.module_name,
      persona: currentCampaign.persona,
      connected_email: currentCampaign.connected_email,
      mission_statement: currentCampaign.mission_statement,
      language: "Auto",
      data_id: currentCampaign.data_id,
      email_per_hour: currentCampaign.email_per_hour,
      ai_sent_allowed: currentCampaign.ai_sent_allowed,
      ai_scheduling: currentCampaign.ai_scheduling,
      sequence_list: currentCampaign.sequences,
    };

    console.log("Campaign payload", campaignData);

    try {
      const response = await axiosInstance.post(
        "/campaign/create-campaign",
        campaignData
      );

      if (response.status === 201) {
        toast({
          title: "Success",
          description: "Campaign saved successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Remove the saved campaign from local storage
        campaigns = campaigns.filter(
          (campaign) => campaign.campaign_id !== campaignId
        );
        localStorage.setItem("campaigns", JSON.stringify(campaigns));
        // console.log("removed from local storage");
        // Navigate back to the campaign page
        history.push(`/campaign`);
      } else {
        throw new Error(response.message || "Failed to save campaign");
      }
    } catch (error) {
      console.error("Error saving campaign:", error);
    }
  };

  const handleSequenceClick = (sequence, index) => {
    // console.log("Sequence clicked", sequence);

    // Parse delay_time to extract number and unit
    const matches = sequence.delay_time.match(/^(\d+)([mhd])$/);
    let number = "";
    let unit = "m"; // default to minutes if no match is found
    if (matches) {
      number = matches[1];
      unit = matches[2];
    }

    // Track the current campaign ID and sequence index
    setCurrentCampaignId(currentCampaign.campaign_id);
    setCurrentSequenceIndex(index);

    // Update state for the form and individual components
    setFormData({
      sequence_name: sequence.sequence_name,
      delay_time: sequence.delay_time, // You might want to update this if you change the input fields
      subject: sequence.subject,
      sequence_template: sequence.sequence_template,
      is_active: sequence.is_active,
      signature: sequence.signature,
    });
    setDelayNumber(number);
    setDelayUnit(unit);

    setSequenceCount(index);
    setEditModalOpen(true);
  };

  // const handleSaveSequence = () => {
  //   if (
  //     !formData.sequence_name ||
  //     !formData.delay_time ||
  //     !formData.sequence_template ||
  //     (sequenceCount < 1 && !formData.subject)
  //   ) {
  //     toast({
  //       title: "Error",
  //       description: "All fields are required.",
  //       status: "error",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //     return;
  //   }
  //   const sequenceIndex = campaigns.findIndex(
  //     (campaign) =>
  //       campaign.campaign_id === campaignId &&
  //       campaign.sequences.some(
  //         (seq) => seq.sequence_id === formData.sequence_id
  //       )
  //   );

  //   if (sequenceIndex !== -1) {
  //     Updating an existing sequence
  //     const updatedCampaigns = campaigns.map((campaign) => {
  //       if (campaign.campaign_id === campaignId) {
  //         return {
  //           ...campaign,
  //           sequences: campaign.sequences.map((seq) => {
  //             if (seq.sequence_id === formData.sequence_id) {
  //               return { ...seq, ...formData };
  //             }
  //             return seq;
  //           }),
  //         };
  //       }
  //       return campaign;
  //     });
  //     setCampaigns(updatedCampaigns);

  //   } else {
  //     // Adding a new sequence
  //     const newSequence = {
  //       ...formData,
  //       sequence_id: Date.now().toString(), // Generate a unique identifier
  //     };

  //     const updatedCampaigns = campaigns.map((campaign) => {
  //       if (campaign.campaign_id === campaignId) {
  //         return {
  //           ...campaign,
  //           sequences: [...campaign.sequences, newSequence],
  //         };
  //       }
  //       return campaign;
  //     });

  //     setCampaigns(updatedCampaigns);
  //   }

  //   toast({
  //     title: "Sequence Saved",
  //     description: "Your sequence has been successfully saved.",
  //     status: "success",
  //     duration: 5000,
  //     isClosable: true,
  //   });
  //   setEditModalOpen(false); // Close the modal
  // };

  const handleSaveSequence = () => {
    if (
      !formData.sequence_name ||
      !formData.delay_time ||
      !formData.sequence_template ||
      (sequenceCount < 1 && !formData.subject)
    ) {
      toast({
        title: "Error",
        description: "All fields are required.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const updatedCampaigns = campaigns.map((campaign) => {
      if (campaign.campaign_id === currentCampaignId) {
        const updatedSequences = campaign.sequences.map((seq, index) => {
          if (index === currentSequenceIndex) {
            return { ...seq, ...formData }; // Update the matching sequence
          }
          return seq; // Return other sequences unchanged
        });

        return {
          ...campaign,
          sequences: updatedSequences, // Use updated sequences
        };
      }
      return campaign; // Return other campaigns unchanged
    });

    setCampaigns(updatedCampaigns);

    toast({
      title: "Sequence Saved",
      description: "Your sequence has been successfully saved.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    setEditModalOpen(false); // Close the modal
  };

  const handleUpdateClick = async (id) => {
    setIsUpdating(true); // Set the updating state to true when update starts
    await UpdateCampaignInsights(id);
    setIsUpdating(false); // Set the updating state to false when update finishes
  };

  return (
    <Flex>
      <Sidebar />
      <Box flex="1" bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}>
        <Box
          p={5}
          pl={6}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          h="10.7vh"
          borderBottom="1px"
          borderColor="blackAlpha.200"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="lg" fontWeight="semibold" mt={4} m={0}>
            Sequences
          </Heading>
        </Box>
        <Box
          as="section"
          m="2"
          h="86.5vh"
          p={4}
          pt={0}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          borderRadius="lg"
          border="1px"
          borderColor="blackAlpha.200"
        >
          <Flex direction="column" h="100%">
            <Box
              flex="1"
              borderBottom="1px"
              borderColor="blackAlpha.200"
              pr="4"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Spacer />
              {isFromBackend && (
                <>
                  {isUpdating ? (
                    <Spinner size="sm" color="blue.500" mr={4} />
                  ) : (
                    <RepeatIcon
                      onClick={() => handleUpdateClick(campaignId)}
                      w={6}
                      h={6}
                      mr={4}
                      cursor="pointer"
                      color="blue.500"
                      size="sm"
                    />
                  )}
                </>
              )}
              {!isFromBackend && (
                <Button
                  mr={4}
                  size="sm"
                  onClick={() => handleSaveCampaign(campaignId)}
                  colorScheme="blue"
                >
                  Publish
                </Button>
              )}
              {!isFromBackend && (
                <Button
                  size="sm"
                  onClick={openAddModal}
                  leftIcon={<Icon as={AddIcon} />}
                  colorScheme="blue"
                >
                  New Sequence
                </Button>
              )}
            </Box>
            <Box
              flex="9"
              m={4}
              borderRadius="md"
              border="1px"
              borderColor="blackAlpha.400"
              overflowY="scroll"
              scrollBehavior="hidden"
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              {currentCampaign &&
                currentCampaign.sequences.map((sequence, index) => (
                  <Flex
                    key={index}
                    h="17%"
                    p={4}
                    bg={colorMode === "dark" ? "gray.700" : "white"}
                    borderTopRadius="md"
                    borderBottom="1px"
                    borderColor="blackAlpha.400"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => handleSequenceClick(sequence, index)}
                    cursor={isFromBackend ? "pointer" : "default"}
                    _hover={{
                      bg: colorMode === "dark" ? "gray.600" : "blackAlpha.50",
                    }}
                  >
                    <VStack
                      alignItems="left"
                      justifyContent="left"
                      spacing={1}
                      w="20%"
                    >
                      <Text fontSize="16px" fontWeight="semibold" mb={1}>
                        {sequence.sequence_name}
                      </Text>
                    </VStack>
                    <Divider
                      orientation="vertical"
                      height="7vh"
                      borderColor="gray.400"
                      sx={{ borderStyle: "dashed" }}
                    />
                    <VStack alignItems="left" justifyContent="left" spacing={1}>
                      <Text
                        fontSize="12px"
                        color={
                          colorMode === "dark" ? "gray.50" : "blackAlpha.700"
                        }
                        mb={1}
                      >
                        Active
                      </Text>
                      <Text fontSize="12px" mb={0}>
                        {currentCampaign.no_of_rows}
                        {/* {sequence.sequence_insight?.is_active ? 'Yes' : 'No'} */}
                      </Text>
                    </VStack>
                    <VStack alignItems="left" justifyContent="left" spacing={1}>
                      <Text
                        fontSize="12px"
                        color={
                          colorMode === "dark" ? "gray.50" : "blackAlpha.700"
                        }
                        mb={1}
                      >
                        Emails Sent
                      </Text>
                      <Text fontSize="12px" mb={0}>
                        {sequence.sequence_insight?.emails_sent || 0}
                      </Text>
                    </VStack>
                    <VStack alignItems="left" justifyContent="left" spacing={1}>
                      <Text
                        fontSize="12px"
                        color={
                          colorMode === "dark" ? "gray.50" : "blackAlpha.700"
                        }
                        mb={1}
                      >
                        Open Count
                      </Text>
                      <Text fontSize="12px" mb={0}>
                        {sequence.sequence_insight?.opened_count || 0}
                      </Text>
                    </VStack>
                    <VStack alignItems="left" justifyContent="left" spacing={1}>
                      <Text
                        fontSize="12px"
                        color={
                          colorMode === "dark" ? "gray.50" : "blackAlpha.700"
                        }
                        mb={1}
                      >
                        Replied Count
                      </Text>
                      <Text fontSize="12px" mb={0}>
                        {sequence.sequence_insight?.replied_count || 0}
                      </Text>
                    </VStack>
                    <VStack alignItems="left" justifyContent="left" spacing={1}>
                      <Text
                        fontSize="12px"
                        color={
                          colorMode === "dark" ? "gray.50" : "blackAlpha.700"
                        }
                        mb={1}
                      >
                        Scheduled Emails
                      </Text>
                      <Text fontSize="12px" mb={0}>
                        {sequence.sequence_insight?.scheduled_emails || 0}
                      </Text>
                    </VStack>
                    <VStack alignItems="left" justifyContent="left" spacing={1}>
                      <Text
                        fontSize="12px"
                        color={
                          colorMode === "dark" ? "gray.50" : "blackAlpha.700"
                        }
                        mb={1}
                      >
                        Delay Time
                      </Text>
                      <Text fontSize="12px" mb={0}>
                        {sequence.delay_time}
                      </Text>
                    </VStack>
                    <VStack alignItems="left" justifyContent="left" spacing={1}>
                      <Text
                        fontSize="12px"
                        color={
                          colorMode === "dark" ? "gray.50" : "blackAlpha.700"
                        }
                        mb={1}
                      >
                        Status
                      </Text>
                      <Tooltip label="Coming soon" placement="top" hasArrow>
                        <Box onClick={(e) => e.stopPropagation()}>
                          <Switch
                            isChecked={true}
                            mb={0}
                            isDisabled
                            sx={{
                              opacity: 1,
                              cursor: "not-allowed",
                              pointerEvents: "auto",
                            }}
                          />
                        </Box>
                      </Tooltip>
                    </VStack>
                  </Flex>
                ))}
            </Box>
          </Flex>
        </Box>
      </Box>
      <SequenceModal
        isOpen={isOpen}
        onClose={onClose}
        handleChange={handleChange}
        handleAddSequence={handleAddSequence}
        formData={formData}
        setFormData={setFormData}
        dataId={currentCampaign.data_id}
        isFromBackend={isFromBackend}
        delayNumber={delayNumber}
        setDelayNumber={setDelayNumber}
        delayUnit={delayUnit}
        setDelayUnit={setDelayUnit}
        sequencesCount={sequenceCount} // Pass the sequence count
      />
      <EditSequenceModal
        isOpen={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        handleChange={handleChange}
        handleSaveSequence={handleSaveSequence}
        formData={formData}
        setFormData={setFormData}
        dataId={currentCampaign.data_id}
        isFromBackend={isFromBackend}
        delayNumber={delayNumber}
        setDelayNumber={setDelayNumber}
        delayUnit={delayUnit}
        setDelayUnit={setDelayUnit}
        sequencesCount={sequenceCount}
      />
    </Flex>
  );
}
export default Sequences;
