import React, { useEffect, useState, useRef } from "react";
import { useColorMode, Box, Text, Heading, Divider } from "@chakra-ui/react";
import usePersistedState from "../../components/usePersistedState";
import Funddocs_fund from "../../components/FundDocs/Funddocs_fund";
import MiddlePanel from "../../components/FundDocs/MiddlePanel";
import Output from "../../components/FundDocs/Output";

const Funddocs = () => {
  const { colorMode } = useColorMode();

  const [fundsList, setFundsList] = usePersistedState(
    "fundsListUploadFund",
    []
  );
  const [fetchedFunds, setFetchedFunds] = useState([]);

  const [selectedFund, setSelectedFund] = usePersistedState(
    "selectedFund",
    null
  );
  // Output
  const [report, setReport] = useState([]);
  const [summary, setSummary] = useState([]);
  //Middle panel
  const [fundName, setFundName] = useState("");
  const [crossRefCheck, setCrossRefCheck] = useState("No");
  const [countryCheck, setCountryCheck] = useState("No");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [marketingMaterial, setMarketingMaterial] = useState(null);
  const [fundProspectus, setFundProspectus] = useState(null);
  const [fundType, setFundType] = useState("");
  const [marketingToRetail, setMarketingToRetail] = useState("No");
  const [insuranceProduct, setInsuranceProduct] = useState("No");
  const [sfdrArticle, setSfdrArticle] = useState("");
  const marketingMaterialInputRef = useRef(null);
  const fundProspectusInputRef = useRef(null);

  const resetFileStates = () => {
    marketingMaterialInputRef.current.value = null;
    fundProspectusInputRef.current.value = null;
  };

  useEffect(() => {
    if (marketingMaterialInputRef.current && fundProspectusInputRef.current) {
      resetFileStates();
    }
  }, [marketingMaterialInputRef, fundProspectusInputRef]);

  return (
    <Box
      display={{ base: "block", md: "flex" }}
      width="100%"
      height="calc(100vh - 52px)"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
    >
      <Box
        w={{ base: "100%", md: "20%" }} // Responsive width
        bg={colorMode === "dark" ? "gray.700" : "white"}
        mt={2}
        ml={2}
        mb={2}
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
      >

        <Funddocs_fund
          selectedFundsId={selectedFund?._id}
          setSelectedFund={setSelectedFund}
          selectedFund={selectedFund}
          fetchedFunds={fetchedFunds}
          setFetchedFunds={setFetchedFunds}
          fundsList={fundsList}
          setFundsList={setFundsList}
          setCrossRefCheck={setCrossRefCheck}
          setCountryCheck={setCountryCheck}
          setSelectedCountries={setSelectedCountries}
          setMarketingMaterial={setMarketingMaterial}
          setFundProspectus={setFundProspectus}
          setFundType={setFundType}
          setMarketingToRetail={setMarketingToRetail}
          setInsuranceProduct={setInsuranceProduct}
          setSfdrArticle={setSfdrArticle}
          fundName={fundName}
          setFundName={setFundName}
          setReport={setReport}
          setSummary={setSummary}
        />
      </Box>
      <Box
        w={{ base: "100%", md: "33%" }} // Responsive width
        bg={colorMode === "dark" ? "gray.700" : "white"}
        mr={2}
        mt={2}
        ml={2}
        mb={2}
        p={4} // Padding inside the box
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"} // Slightly lighter border for visibility
        overflowY="scroll"
        scrollBehavior="hidden"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <MiddlePanel
          resetFileStates={resetFileStates}
          selectedFundsId={selectedFund?._id}
          selectedFund={selectedFund}
          setSelectedFund={setSelectedFund}
          setFetchedFunds={setFetchedFunds}
          setFundsList={setFundsList}
          crossRefCheck={crossRefCheck}
          setCrossRefCheck={setCrossRefCheck}
          countryCheck={countryCheck}
          setCountryCheck={setCountryCheck}
          selectedCountries={selectedCountries}
          setSelectedCountries={setSelectedCountries}
          marketingMaterial={marketingMaterial}
          setMarketingMaterial={setMarketingMaterial}
          fundProspectus={fundProspectus}
          setFundProspectus={setFundProspectus}
          fundType={fundType}
          setFundType={setFundType}
          marketingToRetail={marketingToRetail}
          setMarketingToRetail={setMarketingToRetail}
          insuranceProduct={insuranceProduct}
          setInsuranceProduct={setInsuranceProduct}
          sfdrArticle={sfdrArticle}
          setSfdrArticle={setSfdrArticle}
          marketingMaterialInputRef={marketingMaterialInputRef}
          fundProspectusInputRef={fundProspectusInputRef}
          fundName={fundName}
          setFundName={setFundName}
          report={report}
          setReport={setReport}
        />
      </Box>
      <Box
        w={{ base: "100%", md: "47%" }} // Responsive width
        bg={colorMode === "dark" ? "gray.700" : "white"}
        mr={2}
        mt={2}
        ml={0}
        mb={2}
        p={4} // Padding inside the box
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"} // Slightly lighter border for visibility
      >
        <Output
          selectedFundsId={selectedFund?._id}
          report={report}
          setReport={setReport}
          selectedFund={selectedFund}
          summary={summary}
          setSummary={setSummary}
        />
      </Box>
    </Box>
  );
};

export default Funddocs;
