import React, { useRef, useState, useEffect, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Box,
  Text,
  useDisclosure,
  ModalFooter,
  FormControl,
  FormLabel,
  Switch,
  Grid,
  GridItem,
  Input,
  Flex,
  Tooltip,
  useColorMode,
  Divider,
  Checkbox,
  HStack,
  Spacer,
  Heading,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  VStack,
} from "@chakra-ui/react";
import DOMPurify from "dompurify";
import axiosInstance from "../../../helpers/axiosInstance";

const CustomToolbar = ({ dynamicVariables, handleVariableClick }) => (
  <div id="toolbar" style={{ height: "7vh" }}>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-link" />
    <button className="ql-list" value="ordered" />
    <button className="ql-list" value="bullet" />
    <Popover placement="top">
      <PopoverTrigger>
        <button className="ql-dynamicVariables">
          <svg viewBox="0 0 24 24" width="18" height="18" fill="blue">
            <path d="M10 2H8v5H6v2h2v6H6v2h2v5h2v-5h2v-2h-2V9h2V7h-2V2zm6 0h-2v5h-2v2h2v6h-2v2h2v5h2v-5h2v-2h-2V9h2V7h-2V2z" />
          </svg>
        </button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Dynamic Variables</PopoverHeader>
        <PopoverBody h="50vh" overflowY="scroll">
          {dynamicVariables.map((variable, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="space-between"
              p={2}
              cursor="pointer"
              onClick={() => handleVariableClick(variable)}
            >
              <Text fontWeight="bold" fontSize="xs" mr={2} w="50%">
                {variable.key}
              </Text>
              <Text fontSize="xs" w="50%">
                {variable.value}
              </Text>
            </Box>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  </div>
);

const EditSequenceModal = ({
  isOpen,
  onClose,
  formData,
  setFormData,
  handleSaveSequence,
  handleChange,
  dataId,
  isFromBackend,
  setDelayNumber,
  delayNumber,
  delayUnit,
  setDelayUnit,
  sequencesCount,
}) => {
  const {
    isOpen: isVarOpen,
    onOpen: onVarOpen,
    onClose: onVarClose,
  } = useDisclosure();
  const quillRefSubject = useRef(null);
  const quillRefBody = useRef(null);
  const { colorMode } = useColorMode();
  const [focusedEditor, setFocusedEditor] = useState(null);
  const [dynamicVariables, setDynamicVariables] = useState([]);
  const [signature, setSignature] = useState("");
  const [previewTemplate, setPreviewTemplate] = useState("");

  // Fetch dynamic variables
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/template/${dataId}`);
        const data = response.data.data;
        const mappedData = Object.entries(data).map(([key, value]) => ({
          key: `{{${key}}}`,
          value: value.toString(),
        }));
        setDynamicVariables(mappedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Fetch signature
  useEffect(() => {
    const fetchSignature = async () => {
      try {
        const response = await axiosInstance.get("/signature/fetch-signature");
        if (response.data.success) {
          const fetchedSignature = response.data.data[0].signature_text; // Assuming you want the first signature
          setSignature(fetchedSignature);
        } else {
          console.error("Error fetching signature:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching signature:", error);
      }
    };

    fetchSignature();
  }, []);

  const handleVariableClick = useCallback(
    (variable) => {
      const currentRef =
        focusedEditor === "subject" ? quillRefSubject : quillRefBody;
      if (currentRef && currentRef.current) {
        const quill = currentRef.current.getEditor();
        const range = quill.getSelection(true);

        if (range) {
          quill.insertText(range.index, `${variable.key} `, "user");
          quill.setSelection(range.index + variable.key.length + 1);
        } else {
          quill.focus();
          const retryRange = quill.getSelection(true);
          if (retryRange) {
            quill.insertText(retryRange.index, `${variable.key} `, "user");
            quill.setSelection(retryRange.index + variable.key.length + 1);
          }
        }
        onVarClose();
      }
    },
    [focusedEditor, onVarClose]
  );

  const handleQuillChange = (value, type) => {
    setFocusedEditor(type);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [type]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, type, value } = e.target;
  
    console.log(`${name} checkbox is now ${checked ? 'checked' : 'unchecked'}`);
  
    setFormData((prevFormData) => {
      let updatedTemplate = prevFormData.sequence_template;
      if (name === "signature") {
        if (checked) {
          updatedTemplate = `${updatedTemplate}\n\n${signature}`;
        } else {
          updatedTemplate = updatedTemplate.replace(new RegExp(`\\n\\n${signature}`, 'g'), '');
          quillRefBody.current.getEditor().setText(updatedTemplate); // Update Quill editor's content
        }
      }
  
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
        sequence_template: updatedTemplate,
      };
    });
    handleChange(e);
  };

  const processTemplate = (template = "") => {
    let processedTemplate = template;
    dynamicVariables.forEach((variable) => {
      const regex = new RegExp(variable.key, "g");
      processedTemplate = processedTemplate.replace(regex, variable.value);
    });
    return processedTemplate;
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
      handlers: {
        dynamicVariables: onVarOpen,
      },
    },
  };

  const handleDelayChange = (valueAsString, valueAsNumber) => {
    setDelayNumber(valueAsString);
    setFormData((prevFormData) => ({
      ...prevFormData,
      delay_time: `${valueAsString}${delayUnit}`,
    }));
  };

  const handleUnitChange = (e) => {
    const value = e.target.value;
    setDelayUnit(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      delay_time: `${delayNumber}${value}`,
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent width="100%">
        <ModalHeader fontSize="sm">Create a New Sequence</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={0} pt={0}>
          <Flex direction="column" w="100%">
            <Flex
              direction={{ base: "column", md: "row" }}
              w="50%"
              align="center"
            >
              <Box flex={1} p={2}>
                <FormControl isRequired>
                  <FormLabel fontSize="md" fontWeight="bold">
                    Sequence name:
                  </FormLabel>
                  <Text fontSize="xs" fontWeight="semibold">
                    Enter the sequence name.
                  </Text>
                  <Input
                    mb={3}
                    // mt={1}
                    size="xs"
                    value={formData.sequence_name}
                    onChange={handleChange}
                    name="sequence_name"
                    placeholder="Enter sequence (e.g. Day 1)"
                    w="50%"
                  />
                </FormControl>
              </Box>

              <Box flex={1} p={2}>
                <FormControl isRequired>
                  <FormLabel fontSize="md" fontWeight="bold">
                    When to start this Setup:
                  </FormLabel>
                  <Text fontSize="xs" fontWeight="semibold">
                    Set the amount of time you'd like to wait between steps.
                  </Text>
                  <Flex w="100%" align="center">
                    <NumberInput
                      mb={3}
                      isRequired
                      size="xs"
                      value={delayNumber}
                      onChange={handleDelayChange}
                      min={0}
                      name="delayNumber"
                      w="20%" // Adjust width as necessary
                      style={{ position: "relative" }}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Select
                      ml={2}
                      mb={3}
                      size="xs"
                      value={delayUnit}
                      onChange={handleUnitChange}
                      name="delayUnit"
                      w="25%" // Adjust width as necessary
                    >
                      <option value="m">minutes</option>
                      <option value="h">hours</option>
                      <option value="d">days</option>
                    </Select>
                    <Text
                      fontSize="xs"
                      fontWeight="bold"
                      pt={0}
                      pl={2}
                      align="center"
                    >
                      after the previous step
                    </Text>
                  </Flex>
                </FormControl>
              </Box>
            </Flex>

            <Flex direction={{ base: "column", md: "row" }} w="100%">
              <Box flex={1} p={2}>
                <Heading fontSize="md" fontWeight="semibold">
                  Template
                </Heading>
                <Box style={{ height: "54vh", overflowY: "hidden" }}>
                  {!sequencesCount >= 1 && (
                    <ReactQuill
                      ref={quillRefSubject}
                      theme="snow"
                      value={formData.subject || ""}
                      onChange={(value) => handleQuillChange(value, "subject")}
                      onFocus={() => setFocusedEditor("subject")}
                      modules={{ toolbar: { container: "#toolbar" } }}
                      placeholder="Enter email subject"
                      style={{
                        height: "8vh",
                        width: "100%",
                      }}
                      readOnly={sequencesCount >= 1}
                    />
                  )}
                  <ReactQuill
                    ref={quillRefBody}
                    theme="snow"
                    value={formData.sequence_template || ""}
                    onChange={(value) =>
                      handleQuillChange(value, "sequence_template")
                    }
                    onFocus={() => setFocusedEditor("body")}
                    modules={{ toolbar: { container: "#toolbar" } }}
                    placeholder="Enter email body template"
                    // style={{ height: "39vh", width: "100%" }}
                    style={{
                      height: sequencesCount >= 1 ? "47vh" : "39vh",
                      width: "100%",
                    }}
                  />
                  <CustomToolbar
                    dynamicVariables={dynamicVariables}
                    handleVariableClick={handleVariableClick}
                  />
                </Box>
              </Box>

              <Box flex={1} p={2}>
                <Heading fontSize="md" fontWeight="semibold">
                  Email Preview
                </Heading>
                <Box
                  borderWidth={1}
                  borderRadius="sm"
                  overflowY="auto"
                  bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
                  style={{ height: "54vh", overflowY: "hidden" }}
                >
                  {!sequencesCount >= 1 && (
                    <Box
                      display="flex"
                      h="8vh" // Adjust height as needed or consider removing if it restricts content
                      align="center"
                      mt={1}
                      ml={3}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          marginRight: "0px",
                          marginTop: "10px",
                          verticalAlign: "center", // Ensures text alignment if using line-height or other factors
                          fontSize: "13px",
                        }}
                      >
                        Subject:
                      </span>
                      <Box display="flex" w="100%" m={0} mb={0} p={0}>
                        <ReactQuill
                          value={processTemplate(formData.subject)}
                          readOnly={true}
                          theme="bubble" // 'bubble' is minimal; 'snow' is full-editor
                          style={{
                            margin: "0px",
                            padding: "0px",
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  <ReactQuill
                    value={processTemplate(formData.sequence_template)}
                    readOnly={true}
                    theme="bubble"
                    style={{ height: "46vh", overflow: "hidden" }}
                  />
                </Box>
              </Box>
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              w="50%"
              align="left"
              justifyContent="left"
            >
              <Box flex={1} p={2}>
                <FormControl isRequired>
                  <Flex alignItems="center">
                    <Checkbox
                      isChecked={formData.is_active}
                      onChange={handleChange}
                      name="is_active"
                      mb={2}
                    />
                    <FormLabel fontSize="sm" ml={2}>
                      Is Active
                    </FormLabel>
                  </Flex>
                </FormControl>
              </Box>
              <Box flex={1} p={2}>
                <FormControl isRequired>
                  <Flex alignItems="center">
                    <Checkbox
                      isChecked={formData.signature}
                      onChange={handleCheckboxChange}
                      name="signature"
                      mb={2}
                    />
                    <FormLabel fontSize="sm" ml={2}>
                      Include Signature
                    </FormLabel>
                  </Flex>
                </FormControl>
              </Box>
              {!isFromBackend && (
                <Box flex={1} p={2} pb={6} pt={1}>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => handleSaveSequence(formData)}
                  >
                    Save
                  </Button>
                </Box>
              )}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditSequenceModal;
