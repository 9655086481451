import React, { useEffect, useState } from "react";
import {
  VStack,
  useToast,
  Button,
  Flex,
  Text,
  Divider,
  Box,
  useColorMode,
  Spinner,
} from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";
import ReportDisplay from "./ReportDisplay";
import FileDownload from "./FileDownload";
import SummaryDisplay from "./SummaryDisplay"; 

const Output = ({
  selectedFundsId,
  selectedFund,
  report,
  setReport,
  summary,
  setSummary,
}) => {
  const toast = useToast();
  const documentId = 1;
  const { colorMode } = useColorMode();
  const [isLoading, setIsLoading] = useState(false);

  const fetchFundDetails = async (selectedFundsId) => {
    try {
      const response = await axiosInstance.post(
        "fund_docs/fetch-fund-docs-by-id",
        { str_id: selectedFundsId }
      );
      if (response.status === 200) {
        const reportData = Array.isArray(response.data.data.report)
          ? response.data.data.report
          : [];
        setReport(reportData);
        setSummary(response.data.data);
      } else {
        console.error(
          "Unexpected Status Code:",
          response.status,
          response.data
        );
        toast({
          title: "Error fetching report",
          description: `Received unexpected status: ${response.status}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error caught in fetchFundDetails:", error);
      toast({
        title: "Error fetching report",
        description: error.response?.data.message || "Failed to fetch data",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (selectedFundsId) {
      fetchFundDetails(selectedFundsId);
    }
  }, [selectedFundsId]);

  const fetchReportData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post("fund_docs/generate-report", {
        str_id: selectedFundsId,
      });
      setIsLoading(false);
      if (response.status === 200) {
        const reportData = Array.isArray(response.data.data.report)
          ? response.data.data.report
          : [];
        setReport(reportData);
        setSummary(response.data.data);
        fetchFundDetails(selectedFundsId);
      } else {
        toast({
          title: "Error fetching report",
          description: `Received status: ${response.status}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Error fetching report",
        description: error.response?.data.message || "Failed to fetch data",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={2}>
      <Flex width="full" justifyContent="space-between">
        <Text
          fontSize="md"
          fontWeight="semibold"
          ml={2}
          mb="4px"
          color={colorMode === "dark" ? "white" : "#1A202C"}
        >
          Report
        </Text>

        {report && report.length === 0 && selectedFund && !selectedFund.id && (
          <Button
            size="sm"
            colorScheme="blue"
            onClick={fetchReportData}
            w="25%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {isLoading ? <Spinner size="sm" /> : "Generate Report"}
          </Button>
        )}
      </Flex>
      <Divider m="0" p="0" />

      <Box
        height="75vh"
        overflowY="scroll"
        scrollBehavior="hidden"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {summary && Object.keys(summary).length > 0 && selectedFund && !selectedFund.id && (
          <SummaryDisplay summary={summary} />
        )}

        {report && report.length > 0 && selectedFund && !selectedFund.id && (
          <ReportDisplay report={report} />
        )}
        {selectedFundsId && report.length > 0 && (
          <FileDownload
            documentId={documentId}
            selectedFundsId={selectedFundsId}
          />
        )}
      </Box>
    </VStack>
  );
};

export default Output;
