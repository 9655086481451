import React, { useState } from "react";
import { Box, List, Image, Flex, IconButton } from "@chakra-ui/react";
import { useColorMode } from "@chakra-ui/react";
import SidebarItem from "./SidebarItem"; // Make sure to import the new component
import {
  MdHome,
  MdCampaign,
  MdUpload,
  MdCreate,
  MdMail,
  MdEmail,
} from "react-icons/md";
import logo from "../../assets/images/NEXUSAI_logo1_icon.png";
import openIcon from "../../assets/icons/sidebar-open.svg";
import closeIcon from "../../assets/icons/sidebar-close.svg";

function Sidebar() {
  const { colorMode } = useColorMode();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <Box
      width={isSidebarOpen ? "15%" : "50px"}
      h="100vh"
      bg={colorMode === "dark" ? "gray.800" : "white"}
      color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
      borderRight="1px"
      borderColor="blackAlpha.200"
    >
      <Flex
        justifyContent={isSidebarOpen ? "space-between" : "center"}
        alignItems="center"
        p="4"
        borderBottom="1px"
        borderColor="blackAlpha.200"
        h="10.7vh"
      >
        {isSidebarOpen && <Image src={logo} height="23px" />}
        <IconButton
          icon={
            <Image src={isSidebarOpen ? closeIcon : openIcon} height="24px" />
          }
          onClick={toggleSidebar}
          bg="white"
          aria-label="Toggle Sidebar"
          size="sm"
          _hover={{ bg: "transparent" }}
        />
      </Flex>
      {isSidebarOpen && (
        <Box p="4" pl={0} pt="8">
          <List spacing={2} pl={5}>
            <SidebarItem icon={MdHome} to="/" label="Home" />
            <SidebarItem icon={MdCampaign} to="/campaign" label="Campaign" />
            <SidebarItem icon={MdUpload} to="/upload" label="Upload" />
            <SidebarItem icon={MdCreate} to="/signature" label="Signature" />
            <SidebarItem icon={MdMail} to="/mailboxes" label="Mailboxes" />
            <SidebarItem icon={MdEmail} to="/emails" label="Emails" />
          </List>
        </Box>
      )}
    </Box>
  );
}

export default Sidebar;
