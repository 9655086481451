import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Image,
  Flex,
  IconButton,
  useColorMode,
  SimpleGrid,
  Center,
  Heading,
  useToast,
  Grid,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import axiosInstance from "../../../helpers/axiosInstance";
import Sidebar from "../Sidebar";
import usePersistedState from "../../usePersistedState";
import outlookImage from "../../../assets/images/outlook.png";
import googleImage from "../../../assets/images/google.png";
import otherImage from "../../../assets/images/mail.png";
import ConnectBox from "./ConnectBox";
import {fetchCredentials} from "./apiServices";

const Mailboxes = () => {
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLinked, setIsLinked] = usePersistedState("linked", false);
  const [credentials, setCredentials] = useState([]);
  const [email, setEmail] = useState("");
  const toast = useToast();

  const API_BASE_URL = "/outlook-mail-box"; // Base URL for your APIs

  const getMicrosoftAuthUrl = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_BASE_URL}/get-microsoft-authentication-url`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const authApi = {
    getMicrosoftAuthUrl,
  };

  const appList = [
    {
      name: "Outlook",
      image: outlookImage,
      connectFunction: authApi.getMicrosoftAuthUrl,
      isDisabled: false,
    },
    {
      name: "Google",
      image: googleImage,
      connectFunction: authApi.getGoogleAuthUrl,
      isDisabled: true,
    },
    ,
    {
      name: "Other",
      image: otherImage,
      connectFunction: authApi.getGoogleAuthUrl,
      isDisabled: true,
    },
  ];

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  const handleConnect = async (connectFunction) => {
    try {
      const response = await connectFunction();

      if (response.status === 200) {
        const authUrl = response.data.data.auth_url;
        openInNewTab(authUrl);
        onClose(); // Close the modal after initiating the link
        toast({
          title: "Success",
          description: "Account connected with the mailbox.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Connection Error",
          description: "Failed to connect with the mailbox.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error connecting:", error);
      toast({
        title: "Network Error",
        description: "Unable to connect. Please try again later.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const unlinkMailbox = async (credId) => {
    try {
      const response = await axiosInstance.delete(
        `${API_BASE_URL}/delete-credentials/${credId}`
      );
      if (response.status === 200) {
        toast({
          title: "Unlink Successful",
          description: "Your mailbox has been successfully unlinked.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        // Reset state related to linked mailbox
        setIsLinked(false);
        setEmail("");
      } else {
        throw new Error("Failed to unlink the mailbox");
      }
    } catch (error) {
      console.error("Error unlinking mailbox:", error);
      toast({
        title: "Unlink Error",
        description:
          "There was an error unlinking the mailbox. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleUnlink = async (credId) => {
    await unlinkMailbox(credId);
  };

  useEffect(() => {
    const initializeCredentials = async () => {
      try {
        const creds = await fetchCredentials();
        //console.log("MAilboxes Credentials",creds);
        setCredentials(creds);
        setIsLinked(true);
        // handle state updates based on fetched credentials
      } catch (error) {
        // handle error
      }
    };

    initializeCredentials();
  }, []);

  

  // useEffect(() => {
  //   fetchCredentials(); // Call this on component mount or after certain actions
  // }, []);

  return (
    <Flex>
      <Sidebar />
      <Box flex="1" bg={colorMode === "dark" ? "gray.700" : "white"}>
        <Box
          as="section"
          // m="2"
          h="85vh"
          p={0}
          // pl={0}
          // pr={0}
          // pt={0}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          // borderRadius="lg"
          // border="1px"
          // borderColor="blackAlpha.200"
        >
          {isLinked && credentials.length > 0 ? (
            credentials.map((cred, index) => (
              <Box>
                <Flex
                  p={5}
                  h="10.7vh"
                  borderBottom="1px"
                  borderColor="blackAlpha.200"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Heading fontSize="lg" fontWeight="semibold" mt={4} m={0}>
                    My Mailboxes
                  </Heading>
                  <Spacer />
                  <Button colorScheme="blue" onClick={onOpen}>
                    Link mailbox
                  </Button>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  p={3}
                  ml={2}
                  mr={2}
                  mt={2}
                  borderRadius="lg"
                  border="1px"
                  borderColor="blackAlpha.200"
                >
                  <Image src={outlookImage} boxSize="30px" />
                  <Text fontSize="sm" mt={3} ml={3}>
                    {cred.outlook_email}
                  </Text>
                  <Spacer />
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<MoreHorizIcon />}
                      variant="outline"
                      size="sm"
                      align="right"
                    />
                    <MenuList>
                      <MenuItem
                        color="red"
                        onClick={() => handleUnlink(cred._id)}
                      >
                        Unlink Mailbox
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              </Box>
            ))
          ) : (
            <Box
              p={5}
              maxW="50%"
              borderRadius="lg"
              overflow="hidden"
              mx="auto"
              mt="20"
            >
              <Center>
                <Box textAlign="center">
                  <Image src={otherImage} height="50px" mx="auto" my={4} />{" "}
                  {/* Replace with your actual mail icon path */}
                  <Text fontSize="md" mb={4}>
                    Link your mailbox to get started
                  </Text>
                  <Text fontSize="sm" mb={6}>
                    Set up your mailbox to scale your outbound and do more with
                    sequences and other features.
                  </Text>
                  <Button colorScheme="blue" onClick={onOpen}>
                    Link mailbox
                  </Button>
                </Box>
              </Center>
            </Box>
          )}

          <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Let's link your mailbox</ModalHeader>
              <Text fontSize="sm" mb={6} ml={6}>
                Set up your mailbox to scale your outbound and do more with
                sequences and other features.
              </Text>
              <Text fontSize="sm" mb={2} ml={6} fontWeight="semibold">
                Choose your email provider.
              </Text>
              <ModalCloseButton />
              <ModalBody h="80vh">
                <Grid
                  templateColumns="repeat(3, 1fr)" // This ensures 3 columns in a single row
                  gap={3}
                  width="100%"
                  height="100%"
                  p={3}
                  bg="none"
                  overflowY="auto"
                >
                  {appList.map((app, index) => (
                    <Box
                      key={index}
                      height="100%"
                      overflowY="auto"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      bg={colorMode === "dark" ? "gray.700" : "white"}
                      borderRadius="lg"
                    >
                      <ConnectBox
                        imageSrc={app.image}
                        altText={`${app.name} Icon`}
                        platformName={app.name}
                        handleConnect={() => handleConnect(app.connectFunction)}
                        isDisabled={app.isDisabled}
                      />
                    </Box>
                  ))}
                </Grid>
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </Box>
    </Flex>
  );
};

export default Mailboxes;
